import Column from "@/Components/Backend/Grid/Column";
import Container from "@/Components/Backend/Grid/Container";
import Row from "@/Components/Backend/Grid/Row";
import WebpPicture from "@/Components/WebpPicture";
import Text from "../../Text";

const IconHeadingSection = ({ children, className, withoutGrid = false, center = false }: { children: React.ReactNode, className?: string, withoutGrid?: boolean; center?: boolean; }) => {
    if (withoutGrid) {
        return (
            <div className={className}>
                <div className="flex">
                    <img src="/storage/img/icons/mini-ping-idea.svg" alt="" width={33} height={40} className="shrink-0 mb-auto mr-4"/>
                    <Text heading="h2">
                        {children}
                    </Text>
                </div>
            </div>
        );
    } else {
        return (
            <div className={className}>
                <Container>
                    <Row>
                        <Column>
                            <div className={`flex ${center ? 'justify-center' : ''}`}>
                                <img src="/storage/img/icons/mini-ping-idea.svg" alt="" width={33} height={40} className="shrink-0 mb-auto mr-4"/>
                                <Text heading="h2">
                                    {children}
                                </Text>
                            </div>
                        </Column>
                    </Row>
                </Container>
            </div>
        );
    }
};

export default IconHeadingSection;
